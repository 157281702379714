export function injectSvgIntoElement(element, svgIcon) {
    return fetch(svgIcon)
        .then(res => res.text())
        .then(text => element.innerHTML = text);
}

export function injectSvgIntoElements(elements, svgIcon) {
    console.log(elements)
    return fetch(svgIcon)
        .then(res => res.text())
        .then(text => {
            Array.from(elements).forEach(element => {
                element.innerHTML = text
            })
        });
}
