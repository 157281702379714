export const CURRENT_LANGUAGE = 'current_language';
export const LANGUAGE_EN = 'en';
export const LANGUAGE_RU = 'ru';

export const TRANSLATIONS = {
    [LANGUAGE_EN]: {
        menu__home: 'Home',
        menu__expertise: 'Expertise',
        menu__timeline: 'Timeline',
        menu__contact: 'Contact',
        home__title: 'Hey there! I\'m Dmitry Kologrivko',
        home__subtitle: 'Software engineer, Frontend Developer',
        expertise__title: 'Expertise',
        expertise__skill_assessment: 'LinkedIn skill assessment and endorsed by colleagues',
        expertise__software_development: 'Software <br /> Development',
        expertise__software_development_description: `
            Experienced in both functional and OOP: Java, Python, JavaScript, TypeScript.
        `,
        expertise__frontend_development: 'Frontend <br /> Development',
        expertise__frontend_development_description: `
             Focused on <span class="expertise__react">React</span> expertise. However,
             complemented by hands-on experience in <span class="expertise__angular">Angular</span>.
        `,
        expertise__backend_development: 'Backend <br /> Development',
        expertise__backend_development_description: `
             Experienced in backend development with proficiency in <span class="expertise__nodejs">Node.js</span>
             frameworks, particularly <span class="expertise__nestjs">Nest.js</span>.
        `,
        timeline__title: 'Timeline',
        timeline__ctrlweb_date: 'JULY 2015',
        timeline__ctrlweb_description: `
            Started working as an Android Developer in <a href="https://ctrlweb.ru/" target="_blank">CtrlWeb</a>,
            Russian an e-commerce specialized web studio.
            <br />
            <br />
            During my employment at the company, I was engaged in the development of diverse Android applications 
            from scratch, as well as providing support for existing ones.
        `,
        timeline__globavend_date: 'JANUARY 2016',
        timeline__globavend_description: `
            Started working as an Android Developer in <a href="https://globavend.com/" target="_blank">Globavend</a>,
            a Hong Kong, Australia-based e-commerce logistics provider.
            <br />
            <br />
            During my tenure at the company, I was involved in the development of Android applications
            for warehouse and transportation processes from scratch.
        `,
        timeline__floship_date: 'MAY 2018',
        timeline__floship_description: `
            Started working as an Android Developer in <a href="https://www.floship.com/" target="_blank">Floship</a>,
            a Hong Kong based e-commerce and logistics company.
            <br />
            <br />
            During my tenure at the company, I successfully adapted to the dynamics of the business and diverse tasks. 
            I developed Android applications for warehouse processes, developed web interfaces for a B2B portal, 
            and organized testing and technical support departments.
        `,
        contacts__title: 'Contact',
        contacts__text: `
            Feel free to connect with me on any of the platforms mentioned below. I'm open to
            collaboration, exciting projects, and networking opportunities.
            <br />
            <br />
            Thank you for visiting my personal website! Let's build something incredible together.
        `
    },
    [LANGUAGE_RU]: {
        menu__home: 'Главная',
        menu__expertise: 'Экспертиза',
        menu__timeline: 'Хронология',
        menu__contact: 'Контакты',
        home__title: 'Привет! Я Дмитрий Кологривко',
        home__subtitle: 'Инженер-программист, Frontend разработчик',
        expertise__title: 'Экспертиза',
        expertise__skill_assessment: 'Оценка навыков на LinkedIn и рекомендации от коллег',
        expertise__software_development: 'Разработка <br /> ПО',
        expertise__software_development_description: `
            Опыт в области как функционального, так и 
            объектно-ориентированного программирования: Java, Python, JavaScript, TypeScript.
        `,
        expertise__frontend_development: 'Frontend <br /> Разработка',
        expertise__frontend_development_description: `
             Сфокусирован на экспертизе в <span class="expertise__react">React</span>. Однако,
             имеется опыт в <span class="expertise__angular">Angular</span>.
        `,
        expertise__backend_development: 'Backend <br /> Разработка',
        expertise__backend_development_description: `
             Опыт в области разработки бэкенда с использованием фреймворков на 
             <span class="expertise__nodejs">Node.js</span>,
             в особенности <span class="expertise__nestjs">Nest.js</span>.
        `,
        timeline__title: 'Хронология',
        timeline__ctrlweb_date: 'ИЮЛЬ 2015',
        timeline__ctrlweb_description: `
            Начал работать Android-разработчиком в <a href="https://ctrlweb.ru/" target="_blank">CtrlWeb</a>,
            российской веб-студии, специализирующейся на электронной коммерции.
            <br />
            <br />
            В течение моего срока работы в компании я занимался разработкой разнонаправленных Android-приложений с нуля 
            и поддержкой уже существующих.
        `,
        timeline__globavend_title: '',
        timeline__globavend_date: 'ЯНВАРЬ 2016',
        timeline__globavend_description: `
            Начал работать Android-разработчиком в компании 
            <a href="https://globavend.com/" target="_blank">Globavend</a>, поставщике логистики электронной коммерции 
            базированной в Гонконге и Австралии.
            <br />
            <br />
            В течение моего срока работы в компании я занимался разработкой Android-приложений для складских 
            и транспортных процессов c нуля.
        `,
        timeline__floship_date: 'МАЙ 2018',
        timeline__floship_description: `
            Начал работать Android-разработчиком в компании 
            <a href="https://www.floship.com/" target="_blank">Floship</a>, базирующейся в Гонконге 
            и специализирующейся на электронной коммерции и логистике.
            <br />
            <br />
            В течение моего срока работы в компании, я успешно адаптировался к динамике бизнеса и разнообразным 
            задачам: разрабатывал Android-приложения для складских процессов, разрабатывал веб-интерфейсы
            для B2B портала, организовал отделы тестирования и технической поддержки.
        `,
        contacts__title: 'Контакты',
        contacts__text: `
            Не стесняйтесь связаться со мной на любой из упомянутых ниже платформ. 
            Я открыт для сотрудничества, увлекательных проектов и возможностей для расширения деловых связей.
            <br />
            <br />
            Спасибо за посещение моего веб-сайта! Давайте вместе создадим нечто невероятное.
        `
    }
};

export function getTranslation() {
    return TRANSLATIONS[getCurrentLanguage()]
}

export function getCurrentLanguage() {
    return localStorage.getItem(CURRENT_LANGUAGE) || navigator.language.substring(0, 2);
}

export function setCurrentLanguage(language) {
    localStorage.setItem(CURRENT_LANGUAGE, language);
}
