import { injectSvgIntoElement } from './utils';
import {
    getTranslation,
    getCurrentLanguage,
    setCurrentLanguage,
    LANGUAGE_EN,
    LANGUAGE_RU
} from './translations';
import './styles.css';
import './favicon.ico';
import './img/avatar.png';
import './img/ctrlweb.png';
import './img/globavend.png';
import './img/floship.png';
import './icons/logo.svg';
import './icons/hamburger-menu.svg';
import './icons/cancel.svg';
import './icons/favicon.svg';
import './icons/pc-engineer.svg';
import './icons/javascript.svg';
import './icons/nodejs.svg';
import engIcon from './icons/eng.svg';
import rusIcon from './icons/rus.svg';
import emailIcon from './icons/email.svg';
import githubIcon from './icons/github.svg';
import linkedinIcon from './icons/linkedin.svg';
import habrIcon from './icons/habr.svg';
import checkedIcon from './icons/checked.svg';

const BS_MD_SIZE = 768;
const MENU_HEIGHT = 66;

class IndexController {
    constructor() {
        this.isMobileMenuShown = false;

        this.setupEventListeners();
        this.setupMenu();
        this.setupIcons();
        this.setupAnimations();
        this.setupPageScrolling();
        this.setupTranslations();
    }

    setupEventListeners() {
        document.getElementById('menu-item-home').onclick = () => {
            this.navigateToSection('home');
        }
        document.getElementById('menu-item-expertise').onclick = () => {
            this.navigateToSection('expertise');
        }
        document.getElementById('menu-item-timeline').onclick = () => {
            this.navigateToSection('timeline');
        }
        document.getElementById('menu-item-contacts').onclick = () => {
            this.navigateToSection('contacts');
        }

        document.getElementsByClassName('navbar__hamburger-menu')[0].onclick = (e) => {
            e.stopPropagation();
            this.showMenu();
        }
        document.getElementsByClassName('navbar__cancel')[0].onclick = (e) => {
            e.stopPropagation();
            this.hideMenu();
        }

        addEventListener('resize', () => this.setupMenu());
    }

    setupMenu() {
        if (window.innerWidth < BS_MD_SIZE && !this.isMobileMenuShown) {
            document.getElementsByClassName('navbar__hamburger-menu')[0].style.display = 'inline-block';
            document.getElementsByClassName('navbar__cancel')[0].style.display = 'none';
            document.getElementsByClassName('navbar__menu')[0].style.display = 'none';
        } else if (window.innerWidth > BS_MD_SIZE && !this.isMobileMenuShown) {
            document.getElementsByClassName('navbar__hamburger-menu')[0].style.display = 'none';
            document.getElementsByClassName('navbar__cancel')[0].style.display = 'none';
            document.getElementsByClassName('navbar__menu')[0].style.display = 'flex';
        } else if (window.innerWidth > BS_MD_SIZE && this.isMobileMenuShown) {
            document.getElementsByClassName('navbar__hamburger-menu')[0].style.display = 'none';
            document.getElementsByClassName('navbar__cancel')[0].style.display = 'none';
            document.getElementsByClassName('navbar__menu')[0].style.display = 'flex';
            this.isMobileMenuShown = false;
        }
    }

    setupIcons() {
        Promise.all([
            injectSvgIntoElement(document.getElementsByClassName('email_icon')[0], emailIcon),
            injectSvgIntoElement(document.getElementsByClassName('github_icon')[0], githubIcon),
            injectSvgIntoElement(document.getElementsByClassName('linkedin_icon')[0], linkedinIcon),
            injectSvgIntoElement(document.getElementsByClassName('habr_icon')[0], habrIcon),
            injectSvgIntoElement(document.getElementsByClassName('checked_icon')[0], checkedIcon),
        ]).then();
    }

    setupAnimations() {
        import ('lottie-web/build/player/lottie_light.min.js')
            .then(lottie => {
                lottie.loadAnimation({
                    container: document.getElementById('home-scroll-down'),
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    path: 'scroll-down-animation.json'
                });
            });
    }

    setupPageScrolling() {
        const pageObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const menuItems = document.getElementsByClassName('navbar__menu')[0]
                        .getElementsByClassName('navbar__menu-item');

                    for (let menuItem of menuItems) {
                        menuItem.classList.remove('navbar__menu-item_active');
                    }

                    document.getElementById(`menu-item-${entry.target.id}`)
                        .classList.add('navbar__menu-item_active');
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: window.innerWidth > BS_MD_SIZE ? 0.5 : 0.3
        });

        document.querySelectorAll('section')
            .forEach((section) => {
                pageObserver.observe(section);
            });
    }

    setupTranslations() {
        const translation = getTranslation();

        // Menu
        document.querySelector('.i18__menu__home').innerHTML =
            translation.menu__home;
        document.querySelector('.i18__menu__expertise').innerHTML =
            translation.menu__expertise;
        document.querySelector('.i18__menu__timeline').innerHTML =
            translation.menu__timeline;
        document.querySelector('.i18__menu__contact').innerHTML =
            translation.menu__contact;

        // Home
        document.querySelector('.i18__home__title').innerHTML =
            translation.home__title;
        document.querySelector('.i18__home__subtitle').innerHTML =
            translation.home__subtitle;

        // Expertise
        document.querySelector('.i18__expertise__title').innerHTML =
            translation.expertise__title;
        document.querySelector('.i18__expertise__skill_assessment').innerHTML =
            translation.expertise__skill_assessment;
        document.querySelector('.i18__expertise__software_development').innerHTML =
            translation.expertise__software_development;
        document.querySelector('.i18__expertise__software_development_description').innerHTML =
            translation.expertise__software_development_description;
        document.querySelector('.i18__expertise__frontend_development').innerHTML =
            translation.expertise__frontend_development;
        document.querySelector('.i18__expertise__frontend_development').innerHTML =
            translation.expertise__frontend_development;
        document.querySelector('.i18__expertise__frontend_development_description').innerHTML =
            translation.expertise__frontend_development_description;
        document.querySelector('.i18__expertise__backend_development').innerHTML =
            translation.expertise__backend_development;
        document.querySelector('.i18__expertise__backend_development_description').innerHTML =
            translation.expertise__backend_development_description;

        // Timeline
        document.querySelector('.i18__timeline__title').innerHTML =
            translation.timeline__title;
        document.querySelector('.i18__timeline__ctrlweb_date').innerHTML =
            translation.timeline__ctrlweb_date;
        document.querySelector('.i18__timeline__ctrlweb_description').innerHTML =
            translation.timeline__ctrlweb_description;
        document.querySelector('.i18__timeline__globavend_date').innerHTML =
            translation.timeline__globavend_date;
        document.querySelector('.i18__timeline__globavend_description').innerHTML =
            translation.timeline__globavend_description;
        document.querySelector('.i18__timeline__floship_date').innerHTML =
            translation.timeline__floship_date;
        document.querySelector('.i18__timeline__floship_description').innerHTML =
            translation.timeline__floship_description;

        // Contacts
        document.querySelector('.i18__contacts__title').innerHTML =
            translation.contacts__title;
        document.querySelector('.i18__contacts__text').innerHTML =
            translation.contacts__text;

        // Setup menu item language
        document.getElementById('menu-item-language').innerHTML = "";
        document.getElementById('menu-item-language').appendChild(this.getLanguageElement());
    }

    showMenu() {
        document.getElementsByClassName('navbar__menu')[0].style.display = 'flex';
        document.getElementsByClassName('navbar__hamburger-menu')[0].style.display = 'none';
        document.getElementsByClassName('navbar__cancel')[0].style.display = 'inline-block';
        this.isMobileMenuShown = true;

        document.onclick = (e) => {
            e.stopPropagation();

            if (e.target.classList.contains('navbar__container') || e.target.classList.contains('navbar__menu')) {
                return
            }

            this.hideMenu();
        };
    }

    hideMenu() {
        document.getElementsByClassName('navbar__menu')[0].style.display = 'none';
        document.getElementsByClassName('navbar__hamburger-menu')[0].style.display = 'inline-block';
        document.getElementsByClassName('navbar__cancel')[0].style.display = 'none';
        this.isMobileMenuShown = false;

        document.onclick = null;
    }

    navigateToSection(sectionId) {
        if (window.innerWidth < BS_MD_SIZE && this.isMobileMenuShown) {
            this.hideMenu();
        }

        window.scrollTo({
            top: document.getElementById(sectionId).offsetTop - MENU_HEIGHT,
            behavior: 'smooth'
        });
    }

    changeLanguage() {
        if (window.innerWidth < BS_MD_SIZE && this.isMobileMenuShown) {
            this.hideMenu();
        }

        setCurrentLanguage(getCurrentLanguage() === LANGUAGE_EN ? LANGUAGE_RU : LANGUAGE_EN);
        this.setupTranslations();
    }

    getLanguageElement() {
        let languageIcon = getCurrentLanguage() === LANGUAGE_EN ? rusIcon : engIcon;
        const el = document.createElement('img');
        el.src = languageIcon;
        el.className = 'navbar__language';
        el.alt = 'Language Icon';
        el.onclick = this.changeLanguage.bind(this);
        return el;
    }
}

addEventListener("DOMContentLoaded", (event) => {
    window.controller = new IndexController();
});
